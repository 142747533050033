@font-face {
  font-family: "BorisBlackBloxx";
  src: url("/BorisBlackBloxx.ttf") format("truetype"); /* TODO: find and add other formats */
}

body {
  margin: 0;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
button,
input,
label {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

video::-internal-media-controls-download-button {
  display:none;
}

video::-webkit-media-controls-enclosure {
  overflow:hidden;
}

video::-webkit-media-controls-panel {
  width: calc(100% + 30px); /* Adjust as needed */
}
